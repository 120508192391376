import React, { useContext, useEffect } from 'react';
import ListItem from '../components/list-item';
import { PATH } from '../helper/config';
import { Helmet } from 'react-helmet';

import CategoryIndex from '../components/journal/category-index';
import Pager from '../components/utils/pager';
import { AppContext, ThemeColor } from '../context/context';
import { OnlyPC, OnlySP } from '../helper/media-helper';
import CategoryIndexSp from '../components/journal/category-index-sp';
import { graphql } from 'gatsby';

const journalPage: React.FunctionComponent<any> = ({ data, pageContext }) => {
  const context = useContext(AppContext);

  useEffect(() => {
    context.setLanguage(
      {
        ja: PATH.JOURNAL,
        en: `/en${PATH.JOURNAL}`
      },
      pageContext.locale
    );
  }, []);

  if (typeof window !== `undefined`) {
    useEffect(() => {
      return () => {
        context.setTheme(ThemeColor.DEFAULT);
      };
    }, [window.location.search]);
  }

  return (
    <div className="journal util__page">
      <Helmet>
        <title>JOURNAL | POST-FAKE</title>
        <meta property="og:title" content={`JOURNAL | POST-FAKE`} />
        <meta name="twitter:site" content={`JOURNAL | POST-FAKE`} />
        <meta name="twitter:title" content={`JOURNAL | POST-FAKE`} />
        <meta property="og:url" content={`https://postfake.com${context.localePath}${PATH.JOURNAL}`} />
      </Helmet>
      <OnlySP>
        <CategoryIndexSp data={pageContext.categoryData} slug={pageContext.slug} />
      </OnlySP>
      <div className="journal__list">
        <OnlyPC classStr="category_pc">
          <CategoryIndex data={pageContext.categoryData} slug={pageContext.slug} />
        </OnlyPC>
        {data.allWpGqlJournalItem.edges.map((elem: any, i: number) => {
          let largeFlag: boolean = false;
          if (i % 5 === 0) largeFlag = true;
          return <ListItem isLarge={largeFlag} data={elem} key={`journal-list-item-${i}`} />;
        })}
      </div>
      <Pager context={pageContext} basePath={PATH.JOURNAL} path={PATH.JOURNAL_PAGE} />
    </div>
  );
};
export default journalPage;

export const query = graphql`
  query journalListQuery($skip: Int!, $limit: Int!, $locale: String!) {
    allWpGqlJournalItem(skip: $skip, limit: $limit, filter: { locale: { locale: { eq: $locale } } }, sort: { fields: date, order: DESC }) {
      edges {
        node {
          databaseId
          date
          gqlJournalCategories {
            nodes {
              name
            }
          }
          optionJournalFields {
            title
            thumbnail {
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
          }
        }
      }
    }
  }
`;
